import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { CommonModule } from '@angular/common';
import { FormInputComponent } from '../form/controls/input';
import { FormSelectComponent } from '../form/controls/select';
import { FormDateComponent } from '../form/controls/date';
import { FormRadioComponent } from '../form/controls/radio';
import { FormTelComponent } from '../form/controls/tel';
import { GiftRecipientInfoFormVm } from './gift-recipient-info-form.vm';
import { GiftRecipientForm } from './gift-recipient-info-form.model';
import { FormCheckBoxComponent } from '../form/controls/checkbox';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { getGiftRecipientFormErrors } from '../../../store/form-messages';
import { Store } from '@ngrx/store';
import { AvaIconComponent } from '../ava-icon/ava-icon.component';
import { AppStore } from '@aaa/emember/store-types';
import { nameSuffix } from '../../../constants/name-suffix';
import { FormTextAreaComponent } from '../form/controls/textarea';
import { sendCardTo } from '../../../constants/send-card-to';
import { getMedicalPlanText } from '@aaa/emember/store-membership';
import { AccidentalMedicalCoverageComponent } from '../accidental-medical-coverage/accidental-medical-coverage';
import { AvaDialogService } from '../services/ava-dialog.service';
import { SmartyAddressComponent } from '../form/controls/smarty-address.component';

@Component({
  selector: 'ava-gift-recipient-info-form',
  template: `
    @if (errorMessages$ | async; as errorMessages) {
      <div [formGroup]="formGroup" class="ava-column ava-gap-2">
        <div class="ava-column ava-gap-2 ava-gift-recipient-info" formGroupName="account">
          <ava-form-input
            [errorMessages]="errorMessages.firstName"
            placeholder="First Name"
            formControlName="firstName"
          />

          <div class="ava-row ava-gap-2">
            <ava-form-input
              [errorMessages]="errorMessages.lastName"
              class="ava-flex"
              placeholder="Last Name"
              formControlName="lastName"
            />

            <ava-form-select
              [options]="nameSuffix"
              allowClear="true"
              formControlName="suffix"
              placeholder="Suffix"
              class="ava-gift-recipient-info__suffix"
            />
          </div>

          @if (showAddressAutocomplete) {
            <ava-form-smarty-address
              [includeOnlyStates]="[formGroup.value.account.state]"
              [errorMessages]="errorMessages.address1"
              placeholder="Address 1"
              formControlName="address1"
              zipcodeFormControlName="zipcode"
              stateFormControlName="state"
              cityFormControlName="city"
            />
          } @else {
            <ava-form-input
              [errorMessages]="errorMessages.address1"
              placeholder="Address 1"
              formControlName="address1"
            />
          }

          <ava-form-input placeholder="Address 2" formControlName="address2" />

          <ava-form-input [errorMessages]="errorMessages.city" placeholder="City" formControlName="city" />

          <div class="ava-row ava-gap-2">
            <ava-form-select
              [errorMessages]="errorMessages.state"
              [options]="memberStates"
              [showSearch]="true"
              placeholder="State"
              formControlName="state"
              class="ava-flex"
            />

            <ava-form-input
              [errorMessages]="errorMessages.zipcode"
              placeholder="Zip Code"
              suffixLabel="Zip"
              formControlName="zipcode"
              class="ava-flex"
            />
          </div>

          <div class="ava-row ava-gap-2 ava-gift-recipient-info__group-controls">
            <ava-form-date
              [errorMessages]="errorMessages.birthday"
              placeholder="Date of Birth (mm/dd/yyyy)"
              formControlName="birthday"
              class="ava-flex"
            />

            <ava-form-tel
              [errorMessages]="errorMessages.phone"
              placeholder="Phone"
              formControlName="phone"
              class="ava-flex"
            />
          </div>
          <div class="ava-row ava-gap-2 ava-gift-recipient-info__group-controls">
            <ava-form-input
              [errorMessages]="errorMessages.email"
              placeholder="Email Address"
              suffixLabel="Email"
              formControlName="email"
              class="ava-flex"
            />
          </div>
          @if (showAccidentMedicalPlan) {
            <div class="ava-row">
              <ava-form-checkbox
                [formLabel]="
                  'Accident Medical ' +
                  (accidentMedicalPlanPrice ? (accidentMedicalPlanPrice | currency) : '') +
                  ' Plan'
                "
                formControlName="accidentMedicalPlan"
                (changed)="accidentMedicalChanged.emit($event)"
              />

              @if (medicalPlanText$ | async; as medicalPlanText) {
                <ava-icon
                  iconType="info-circle"
                  iconTheme="fill"
                  tooltipPlacement="top"
                  [tooltipTitle]="medicalPlanText"
                  (tooltipClicked)="tooltipClicked()"
                />
              }
            </div>
          }
        </div>

        @if (showGiftOption) {
          <div formGroupName="options" class="ava-column ava-gap">
            <p class="ava-header-bold">Gift Options</p>

            @if (showGiftMessage) {
              <div class="ava-column ava-gap-2">
                <div class="ava-row ava-gap-2 ava-gift-recipient-info__group-controls">
                  <ava-form-input class="ava-flex" placeholder="To:" suffixLabel="To" formControlName="to" />

                  <ava-form-input class="ava-flex" placeholder="From:" suffixLabel="From" formControlName="from" />
                </div>

                <ava-form-textarea
                  class="ava-flex"
                  placeholder="Gift Message:"
                  suffixLabel="Gift Message"
                  formControlName="message"
                />
              </div>
            }

            <div class="ava-column ava-gap-2">
              <ava-form-radio formControlName="sendCardTo" formLabel="Send Gift To:" [options]="sendCardTo" />

              <ava-form-checkbox
                formControlName="autoRenew"
                formLabel="Make this a recurring yearly gift"
                (changed)="autoRenewChanged.emit()"
              >
                <ava-icon
                  iconTheme="fill"
                  iconType="info-circle"
                  tooltipPlacement="top"
                  [tooltipTitle]="autoRenewTemplate"
                />
              </ava-form-checkbox>
            </div>
          </div>
        }
      </div>
    }

    <ng-template #autoRenewTemplate>
      When enrolled in our auto renewal program, your AAA Gift Membership dues will renew annually on the debit or
      credit card of your choice. You will receive a renewal notification 45-60 days before your AAA Gift Membership
      expiration date
    </ng-template>
  `,
  styles: [
    `
      @import '../../../styles/ava-responsive';

      .ava-gift-recipient-info {
        padding-bottom: 16px;

        &__suffix {
          max-width: 100px;
          width: 100%;
        }

        @include screenTabletDown() {
          .ava-gift-recipient-info__group-controls {
            flex-direction: column;
          }
        }
      }
    `,
  ],
  imports: [
    FormInputComponent,
    FormSelectComponent,
    ReactiveFormsModule,
    NzIconModule,
    FormDateComponent,
    FormRadioComponent,
    FormTelComponent,
    CommonModule,
    FormCheckBoxComponent,
    NzToolTipModule,
    AvaIconComponent,
    FormTextAreaComponent,
    SmartyAddressComponent,
  ],
  standalone: true,
})
export class GiftRecipientInfoFormComponent {
  readonly nameSuffix = nameSuffix;
  readonly sendCardTo = sendCardTo;
  @Input() memberStates = [];
  @Input() showGiftOption = false;
  @Input() showGiftMessage = false;
  @Input() showAccidentMedicalPlan = false;
  @Input() accidentMedicalPlanPrice = 0;
  @Input() showAddressAutocomplete = false;
  @Output() accidentMedicalChanged = new EventEmitter<void>();
  @Output() autoRenewChanged = new EventEmitter<void>();

  dialog = inject(AvaDialogService);
  formVm = inject(GiftRecipientInfoFormVm);
  controlContainer = inject(ControlContainer);
  store = inject(Store<AppStore>);
  errorMessages$ = this.store.select(getGiftRecipientFormErrors);
  medicalPlanText$ = this.store.select(getMedicalPlanText);

  get formGroup(): FormGroup<GiftRecipientForm> {
    if (this.controlContainer) {
      return this.controlContainer.control as FormGroup<GiftRecipientForm>;
    }

    return this.formVm.create();
  }

  tooltipClicked() {
    this.dialog.openComponent({
      nzContent: AccidentalMedicalCoverageComponent,
      nzFooter: null,
    });
  }
}
