import { inject, Injectable } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormGroupValue } from '../form.utils';
import { ValidatorService } from '../form/validators/validator.service';
import { AccountForm, MemberInfoForm } from './member-info-form.model';
import { MembershipAssociateVm } from '../membership-associate-form';

@Injectable({ providedIn: 'root' })
export class MemberInfoFormVm {
  fb = inject(FormBuilder);
  membershipAssociateVm = inject(MembershipAssociateVm);
  validator = inject(ValidatorService);

  create(partialValues: Partial<FormGroupValue<MemberInfoForm>> = {}) {
    const form = this.fb.group<MemberInfoForm>({
      account: this.fb.group<AccountForm>({
        firstName: new FormControl('', {
          nonNullable: true,
          validators: Validators.compose([Validators.required, this.validator.isTextString()]),
          updateOn: 'blur',
        }),
        lastName: new FormControl('', {
          nonNullable: true,
          validators: Validators.compose([Validators.required, this.validator.isTextString()]),
          updateOn: 'blur',
        }),
        suffix: new FormControl('', { nonNullable: true }),
        address1: new FormControl('', {
          nonNullable: true,
          validators: Validators.compose([Validators.required]),
          updateOn: 'blur',
        }),
        address2: new FormControl('', { nonNullable: true }),
        city: new FormControl('', {
          nonNullable: true,
          validators: Validators.compose([Validators.required]),
          updateOn: 'blur',
        }),
        state: new FormControl('', {
          nonNullable: true,
          validators: Validators.compose([Validators.required]),
        }),
        zipcode: new FormControl('', {
          nonNullable: true,
          asyncValidators: [this.validator.zipCodeIsInClub()],
          validators: Validators.compose([
            Validators.required,
            this.validator.zipCodeFormat(),
            this.validator.zipCodeIsInRegion('state'),
          ]),
          updateOn: 'blur',
        }),
        birthday: new FormControl('', {
          nonNullable: true,
          validators: Validators.compose([this.validator.dateFormat()]),
          updateOn: 'blur',
        }),
        phone: new FormControl('', {
          nonNullable: true,
          validators: Validators.compose([this.validator.phoneNumber()]),
          updateOn: 'blur',
        }),
        email: new FormControl('', {
          nonNullable: true,
          asyncValidators: [this.validator.emailExistInClub()],
          validators: Validators.compose([Validators.required, this.validator.email()]),
          updateOn: 'blur',
        }),
        password: new FormControl('', {
          nonNullable: true,
          validators: Validators.compose([Validators.required, this.validator.password()]),
          updateOn: 'blur',
        }),
        accidentMedicalPlan: new FormControl(false, { nonNullable: true }),
      }),
      membership: this.fb.group({
        cardFormat: new FormControl('', { nonNullable: true }),
        membershipLevel: new FormControl('', { nonNullable: true }),
        rv: new FormControl(true, { nonNullable: true }),
        promoCode: new FormControl('', { nonNullable: true }),
        couponCode: new FormControl('', { nonNullable: true }),
        programCode: new FormControl('', { nonNullable: true }),
      }),
      membershipAssociates: this.membershipAssociateVm.create(),
    });

    form.controls.account.controls.zipcode.valueChanges.subscribe(() => {
      form.controls.account.controls.state.markAsDirty();
      form.controls.account.controls.state.markAsTouched();
      form.controls.account.controls.state.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    });

    form.controls.account.controls.state.valueChanges.subscribe(() => {
      form.controls.account.controls.zipcode.markAsDirty();
      form.controls.account.controls.zipcode.markAsTouched();
      form.controls.account.controls.zipcode.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    });

    form.patchValue(partialValues);

    return form;
  }
}
