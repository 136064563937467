import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { encryptPassword } from '../utils/encrypt-password';
import { JoinActions } from './join.actions';
import { exhaustMap, of, switchMap, withLatestFrom } from 'rxjs';
import {
  CardFormat,
  ConnectSuiteMembershipLevel,
  DonorMembership,
  DrupalNewLoginAccountCredentials,
  LastNameSuffix,
  MembershipConnectSuiteMethod,
  MembershipConnectSuiteOperationExecuteEventPayload,
  MembershipConnectSuiteRecostValidateJoinEventPayload,
  MembershipPayloadAssociate,
} from '@aaa/interface-joinRenew-membership-membershipConnectSuite';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Operation, OperationExecutePayload } from '@aaa/interface-joinRenew-joinRenewLib';
import { parseDateToApi } from '../utils/parse-date-to-api';
import { FormGroupValue } from '../../modules/share/form.utils';
import { getJoinFormOfferSummary, getJoinFormPage, getJoinFormSelectedLevel } from './join.selectors';
import { filterByClubIds } from '../utils/filter-by-club-ids';
import { MembershipCode, ValidateSucceededResponseObject } from '../types/types';
import {
  PaymentCybersourceMethod,
  PaymentCybersourceOperationExecuteEventPayload,
} from '@aaa/interface-joinRenew-payment-paymentCybersource';
import { getPayment } from '@aaa/emember/store-payment';
import { JoinForm, JoinPage, JoinPayParams } from './join.models';
import { map as _map } from 'lodash-es';
import { ConnectSuite } from '../connect-suite.type';
import { PaymentForm } from '@aaa/emember/share/payment-form';
import { ConfirmedMember } from '../../modules/share/membership-card-detail-list/types';
import { ExecuteService } from '../services/execute.service';
import { ClubApp } from '@aaa/emember/types';
import { checkCybersourcePaymentValidation } from '../check-cybersource-payment-validation';
import { RequestError, RequestErrorType } from '../generic-errors';
import { MembershipOfferItem, MembershipOfferSummary } from '../price-offers/helpers/types';
import { getPriceOfferLevels, MembershipsLevel, PriceOffersActions } from '@aaa/emember/store-price-offers';
import { checkOperationErrorsConnectSuiteSystem } from '../check-operation-errors-connect-suite-system';
import { Cybersource } from '../cybersource.type';
import { DataLayerService } from '../../modules/share/services/data-layer.service';
import { AnalyticsPurchaseEvent } from '../../../types/analytics-purchase-event';
import { getClearCacheSettings } from '../utils/get-cache-settings';
import { AppAnalyticsEvents } from '../../../types/analytics-events';
import { getTransactionId } from '../utils/get-transaction-id';

@Injectable({ providedIn: 'root' })
export class JoinConnectSuiteEffects {
  store = inject(Store);
  actions$ = inject(Actions).pipe(filterByClubIds(this.store, [ClubApp.Hoosier, ClubApp.SouthJersey]));
  executeService = inject(ExecuteService);
  dataLayer = inject(DataLayerService);

  // update discount and fees after validated form
  updateSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinActions.recostValidateSucceeded),
      mergeMap(({ response }: ValidateSucceededResponseObject<ConnectSuite.JoinRecostValidationResponseObject>) => {
        const membershipType = response.response.validationData.membership.primaryMember.attributes.membershipType;
        const componentOffers: MembershipOfferItem[] = [];
        const costSummary = response?.response.validationData?.membership?.costSummary;
        const membershipOptions = response?.response.validationData?.membership?.membershipOptions;
        const totalCost = Number(costSummary?.totalCost || 0);

        if (membershipOptions) {
          membershipOptions.duesComponent
            .filter((component) => !!component)
            .map((component) => {
              if (component.attributes.componentCode === 'AR') {
                componentOffers.push({
                  offering: 'optionalPrimary',
                  code: 'autorenew',
                  description: 'Auto Renew Discount',
                  amount: Number(component.attributes.componentAmount),
                  selectedByDefault: false,
                  conditions: [
                    {
                      apply: 'autorenew',
                      value: true,
                      operator: 'equal',
                    },
                  ],
                });
              }
            });
        }

        if (costSummary) {
          const autoRenewDiscountAmount = Number(costSummary.autoRenewDiscount);
          const notHasAR = !!componentOffers.find((c) => c.code !== 'autorenew');

          if (autoRenewDiscountAmount && notHasAR) {
            componentOffers.push({
              offering: 'optionalPrimary',
              code: 'autorenew',
              description: 'Auto Renew Discount',
              amount: autoRenewDiscountAmount,
              selectedByDefault: false,
              conditions: [
                {
                  apply: 'autorenew',
                  value: true,
                  operator: 'equal',
                },
              ],
            });
          }

          const solicitationDiscountAmount = Number(costSummary.solicitationDiscount);
          if (solicitationDiscountAmount) {
            const solicitationRequiresAutoRenew = costSummary?.solicitationRequiresAutoRenew === 'YES';
            const promotionalCode = response.response.validationData.attributes.promotionalCode;
            const couponCode = response.response.validationData.attributes.couponCode;
            const hasPromoCode = !!promotionalCode && !couponCode;
            const hasCouponCode = !!promotionalCode && !!couponCode;

            const offer: MembershipOfferItem = {
              offering: 'optionalPrimary',
              code: hasCouponCode ? 'couponCode' : 'promoCode',
              description: `(${(response?.response.validationData?.attributes?.promotionalCode || '').toUpperCase()})`,
              amount: solicitationDiscountAmount,
              selectedByDefault: false,
              conditions: [],
            };

            if (hasPromoCode) {
              offer.conditions.push({ apply: 'promoCode', value: true, operator: 'equal' });
            }

            if (solicitationRequiresAutoRenew) {
              offer.conditions.push({ operator: 'equal', apply: 'autorenew', value: true });
            }

            componentOffers.push(offer);
          }

          const waiveEnrollDiscount = Number(costSummary.waiveEnrollDiscount || 0);
          if (waiveEnrollDiscount) {
            componentOffers.push({
              offering: 'optionalPrimary',
              code: 'waiveEnrollFee',
              description: 'One-Time Enrollment Fee',
              amount: waiveEnrollDiscount,
              selectedByDefault: true,
              conditions: [],
            });
          }
        }

        return [
          JoinActions.setTotalCost({ totalCost }),
          PriceOffersActions.updateAndInsert({ membershipType, componentOffers }),
        ];
      }),
    ),
  );

  setConfirmedMembersSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinActions.paySucceeded),
      switchMap((action) => of(action).pipe(withLatestFrom(this.store.select(getPriceOfferLevels)))),
      map(([{ membership }, membershipLevels]) => {
        const members = this.getMembers(membership, membershipLevels);

        return JoinActions.setConfirmedMembers({ members });
      }),
    ),
  );

  recostValidateJoin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        JoinActions.recostValidate,
        JoinActions.updateRecostValidate,
        JoinActions.validatePromoCode,
        JoinActions.retryRecostValidate,
      ),
      switchMap(({ formValues }) => {
        const getRecostValidation$ = of(null).pipe(
          switchMap(() =>
            of(null).pipe(
              withLatestFrom(
                this.store.select(getJoinFormOfferSummary),
                this.store.select(getPayment),
                this.store.select(getJoinFormSelectedLevel),
                this.store.select(getJoinFormPage),
              ),
            ),
          ),
          switchMap(([, summary, payment, level, page]) =>
            this.recostValidation(formValues, summary, payment, level, page).pipe(
              map((res) => JoinActions.recostValidateSucceeded(res as ValidateSucceededResponseObject)),
            ),
          ),
        );

        return of(null).pipe(
          switchMap(() => getRecostValidation$),
          catchError((error) => of(JoinActions.recostValidateFailed({ error }))),
        );
      }),
    ),
  );

  pay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JoinActions.pay),
      exhaustMap(({ params }) =>
        this.pay(params).pipe(
          map(({ membership, payment }) => JoinActions.paySucceeded({ membership, payment })),
          catchError((error) => of(JoinActions.payFailed({ error }))),
        ),
      ),
    ),
  );

  pay(params: JoinPayParams) {
    const { formValues, totalCost, executionData, payment, selectedLevel, page } = params;
    const paymentEvent: PaymentCybersourceOperationExecuteEventPayload = {
      executionData: {
        flexMicroFormToken: payment.token,
        billTo: {
          address1: String(formValues?.billing?.billingTo?.address1),
          address2: String(formValues?.billing?.billingTo?.address2),
          administrativeArea: String(formValues?.billing?.billingTo?.state),
          buildingNumber: '',
          country: 'US',
          district: String(formValues?.billing?.billingTo?.state),
          email: String(
            (page === 'gift' ? formValues.giftInfo : formValues?.memberInfo)?.account?.email || 'fallback@avagate.com',
          ),
          firstName: String(formValues?.billing?.billingTo?.firstName),
          lastName: String(formValues?.billing?.billingTo?.lastName),
          locality: String(formValues?.billing?.billingTo?.city),
          phoneNumber: String((page === 'gift' ? formValues.giftInfo : formValues?.memberInfo)?.account?.phone),
          postalCode: String(formValues?.billing?.billingTo?.zipcode),
        },
        amountDetails: {
          totalAmount: String(totalCost),
          currency: 'USD',
        },
        creditCardBrandedName: payment.formValues?.card?.cardName || '',
      },
      method: PaymentCybersourceMethod.OPERATION_EXECUTE,
      operation: Operation.JOIN,
    };
    const loginCredentials: DrupalNewLoginAccountCredentials = {
      email: formValues.memberInfo?.account?.email,
      encryptedPassword: encryptPassword(formValues.memberInfo?.account?.password),
      password: String(formValues.memberInfo?.account?.password),
      zip: formValues.memberInfo?.account?.zipcode,
      iso: '',
      club: '',
      household: '',
      associate: '',
      check_digit: '',
    };
    const membershipEvent: MembershipConnectSuiteOperationExecuteEventPayload = {
      executionData: executionData,
      method: MembershipConnectSuiteMethod.OPERATION_EXECUTE,
      operation: Operation.JOIN,
      loginCredentials: loginCredentials,
      cacheSettings: getClearCacheSettings(),
    };
    const coupon = formValues.memberInfo?.membership?.couponCode || formValues.memberInfo?.membership?.promoCode || '';
    const payload: OperationExecutePayload = {
      membershipEvent: membershipEvent,
      operation: Operation.JOIN,
      paymentEvent: paymentEvent,
    };

    return this.executeService
      .execute<ConnectSuite.JoinExecuteResponseObject, Cybersource.ExecutePaymentResponseObject>(payload)
      .pipe(
        map(({ validateObject, paymentObject, operationObject }) => {
          const paymentError = !!paymentObject?.meta.isError;

          if (paymentError) {
            checkCybersourcePaymentValidation(paymentObject.error);
          }

          const analyticsEventParams: AnalyticsPurchaseEvent['eventParams'] = {
            currency: 'USD',
            transaction_id: getTransactionId(paymentObject),
            value: totalCost,
            items: [
              {
                quantity: 1,
                item_id: 'primary',
                price: totalCost,
                item_name: page === 'join' ? AppAnalyticsEvents.JoinNew : AppAnalyticsEvents.GiftNew,
                coupon,
              },
            ],
            context: 'ava-store ' + (page === 'join' ? AppAnalyticsEvents.JoinNew : AppAnalyticsEvents.GiftNew),
            membershipLevel: selectedLevel.level,
            coupon,
          };
          this.dataLayer.purchaseEvent(analyticsEventParams);

          const membershipError =
            validateObject?.meta.isError || validateObject?.response.response?.attributes?.responseCode !== '000';

          if (membershipError) {
            throw new RequestError(RequestErrorType.MembershipError, validateObject);
          }

          const operationError = !!operationObject?.meta?.isError;
          if (operationError) {
            checkOperationErrorsConnectSuiteSystem(operationObject.error, operationObject);
          }

          return { membership: validateObject, payment: paymentObject };
        }),
      );
  }

  recostValidation(
    formValues: FormGroupValue<JoinForm>,
    offers: MembershipOfferSummary,
    payment: { token: string; formValues: FormGroupValue<PaymentForm> },
    selectedLevel: MembershipsLevel | null,
    page: JoinPage,
  ) {
    const formValuePage = page === 'gift' ? formValues.giftInfo : formValues?.memberInfo;
    const donorMembership =
      page === 'gift'
        ? ({
            address: {
              attributes: {
                country: 'US',
                address1: formValues.memberInfo?.account?.address1 || '',
                address2: formValues.memberInfo?.account?.address2 || '',
                cityName: formValues.memberInfo?.account?.city || '',
                StateProv: formValues.memberInfo?.account?.state || '',
                postalCode: formValues.memberInfo?.account?.zipcode || '',
              },
            },
            cellPhone: formValues.memberInfo?.account?.phone || '',
            homePhone: '',
            email: formValues.memberInfo?.account?.email || '',
            firstName: formValues.memberInfo?.account?.firstName || '',
            lastName: formValues.memberInfo?.account?.lastName || '',
            // membershipNumber: formValues.memberInfo?.account?.membershipNumber || "",
            // middleIntial: formValues.memberInfo?.account?.suffix || "",
            nameSuffix: formValues.memberInfo?.account?.suffix || '',
            // title: formValues.memberInfo?.account?.suffix || "",
            giftOptions: {
              giftFrom: formValues.giftInfo?.options?.from,
              giftMessage: formValues.giftInfo?.options?.message,
              giftTo: formValues.giftInfo?.options?.to,
              sendMbrCardTo: formValues.giftInfo?.options?.sendCardTo === 'purchaser' ? 'D' : 'P',
              renewalType: formValues.giftInfo?.options?.autoRenew ? 'P' : 'O', //perpetual | one-time
              // holidayGift: boolean;
            },
          } as DonorMembership)
        : null;

    const payload: MembershipConnectSuiteRecostValidateJoinEventPayload = {
      method: MembershipConnectSuiteMethod.RECOST_VALIDATE_JOIN,
      membership: {
        membershipLevel: formValues.memberInfo?.membership?.membershipLevel as ConnectSuiteMembershipLevel,
        rv: !!formValues?.memberInfo?.membership?.rv,
        associateCount: formValues?.memberInfo?.membershipAssociates?.length || 0,
        address: {
          address1: formValuePage?.account?.address1 || 'Fake address',
          address2: formValuePage?.account?.address2 || '',
          cityName: formValuePage?.account?.city || 'Fake City Name',
          StateProv: formValuePage?.account?.state || '',
          postalCode: formValuePage?.account?.zipcode || '',
        },
        promoCode: formValues?.memberInfo?.membership?.promoCode || '',
        couponCode: formValues?.memberInfo?.membership?.couponCode || '',
        programCode: formValues?.memberInfo?.membership?.programCode || '',
        autoRenew: page === 'join' ? !!payment.formValues?.autoRenew : !!formValues.giftInfo?.options?.autoRenew,
        cardFormat: formValues?.memberInfo?.membership?.cardFormat as CardFormat,
      },
      primary: {
        firstName: formValuePage?.account?.firstName || 'Fake First Name',
        lastName: formValuePage?.account?.lastName || 'Fake Last Name',
        dob: parseDateToApi(formValuePage?.account?.birthday), // need to convert
        cellPhone: formValuePage?.account?.phone || '',
        email: formValuePage?.account?.email || '', // need to convert
        nameSuffix: formValuePage?.account?.suffix as LastNameSuffix,
        businessPhone: '',
        homePhone: '',
        sex: '',
        title: '',
        middleIntial: '',
      },
      associates: formValues?.memberInfo?.membershipAssociates?.map(
        (associate) =>
          ({
            firstName: associate.firstName,
            lastName: associate.lastName,
            email: associate.email,
            dob: parseDateToApi(associate.birthday),
            removeAssociate: associate.removeAssociate,
            nameSuffix: associate.suffix,
            middleIntial: associate.middleIntial,
            membershipNumber: associate.membershipNumber,
          }) as MembershipPayloadAssociate,
      ),
      donorMembership: donorMembership,
    };

    return this.executeService.membershipQuery<ConnectSuite.JoinRecostValidationResponseObject>(payload).pipe(
      map((validateObject) => {
        const isError: boolean = validateObject.meta.isError;
        const missingRequirements = validateObject.response?.missingRequirements;

        if (missingRequirements) {
          throw new RequestError(RequestErrorType.JoinFormMissingRequirementsError, validateObject);
        }

        if (isError) {
          switch (validateObject.error.responseCode) {
            case '040': // Membership already exists and is in the Active status
            case '090': // Membership already exists
            case '091': // Membership already exists.
            case '092': // Membership already exists.
              throw new RequestError(RequestErrorType.MembershipAlreadyExistingError, validateObject);
            case '012': // invalid promoCode
            case '013': // invalid promoCode
              /**
               * re-run recost without promoCode
               */
              throw new RequestError(RequestErrorType.MembershipInvalidPromoCode, validateObject);
            case '033': // Outside age limit range
              throw new RequestError(RequestErrorType.JoinFormMinAgeRequireError, validateObject);
            default:
              // Todo: Show alert
              // this.notification.openFromComponent(AlertCriticalErrorComponent)
              throw new RequestError(RequestErrorType.JoinFormError, validateObject);
          }
        } else {
          return {
            response: validateObject,
            executionData: validateObject.response.executionData,
          };
        }
      }),
    );
  }

  getMemberFromMemberInfo(
    membershipCodes: MembershipCode[],
    formValues: Partial<FormGroupValue<JoinForm>>,
  ): ConfirmedMember[] {
    const getMembershipCode = (membershipLevel?: string) =>
      membershipCodes.find((code) => String(code.level).toLowerCase() === String(membershipLevel).toLowerCase());
    const membershipLevel = getMembershipCode(formValues.memberInfo?.membership?.membershipLevel)?.label || '';
    const primaryMember: ConfirmedMember = {
      expirationDate: '',
      membershipNumber: '',
      fullName: [
        formValues.memberInfo?.account?.firstName,
        formValues.memberInfo?.account?.lastName,
        formValues.memberInfo?.account?.suffix,
      ].join(' '),
      membershipLabel: membershipLevel,
      membershipUserId: '',
    };
    const associates: ConfirmedMember[] =
      formValues.memberInfo?.membershipAssociates?.map((associate) => ({
        expirationDate: '',
        membershipNumber: '',
        fullName: [associate.firstName, associate.lastName, associate.suffix].join(' '),
        membershipLabel: membershipLevel,
        membershipUserId: associate.email || 'node',
      })) || [];

    return [primaryMember, ...associates];
  }

  getMembers(payload: ConnectSuite.JoinExecuteResponseObject, membershipLevels: MembershipsLevel[]) {
    const membership = payload.response.response.membership;
    const primary = membership.primaryMember;
    const getMembershipLevel = (membershipType: string) =>
      membershipLevels.find((code) => code.membershipType === membershipType);
    const parseDate = (date = '') => [date.slice(4, 6), date.slice(6, 8), date.slice(0, 4)].join('/');
    const primaryMember: ConfirmedMember = {
      expirationDate: parseDate(membership.attributes?.expDate),
      membershipLabel: getMembershipLevel(primary.attributes.membershipType)?.name || '',
      membershipNumber: primary.attributes.membershipNumber,
      membershipUserId: primary.attributes.email,
      fullName: [primary.attributes.firstName, primary.attributes.lastName, primary.attributes.nameSuffix].join(' '),
    };
    const associates = _map(membership.associateMember).map(
      (associate) =>
        ({
          expirationDate: parseDate(membership.attributes.expDate),
          membershipLabel: getMembershipLevel(associate.attributes.membershipType)?.name || '',
          membershipNumber: associate.attributes.membershipNumber,
          membershipUserId: associate.attributes.email || 'none',
          fullName: [
            associate.attributes.firstName,
            associate.attributes.lastName,
            associate.attributes.nameSuffix,
          ].join(' '),
        }) as ConfirmedMember,
    );

    return [primaryMember, ...associates];
  }
}
